<template>
  <v-container fluid>
    <!-- editor -->
    <editor
      :initial="dataItem"
      :pageMode="pageMode"
    ></editor>
    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../_components/editor.vue";
export default {
  components: {
    Editor,
  },

  data: () => ({
    dataItem: {},
    pageMode: "view",
  }),
  watch: {
    "$route.params.id": {
      handler: "getBpMaster",
      immediate: true,
    },
  },
  methods: {
    getBpMaster(val) {
      const self = this;
      this.$store.commit("loader", true);
      this.$store
        .dispatch("get", `/web-gpm-scan-logs/${val}`)
        .then((res) => {
          self.dataItem = res.ResponseData;
          this.$store.commit("loader", false);
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {},
};
</script>