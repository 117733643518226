<template>
  <v-form>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Scan Log Details</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="flatClose" icon to="/gpm-scan-logs">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar color="secondary" flat dense>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          to="/gpm-scan-logs"
        >
          <v-icon left dark>mdi-eye</v-icon>All Scan Logs
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 2 -->
      <v-card-text>
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              outlined
              dense
              readonly
              filled
              label="Scanned Document Number"
              v-model="record.DocNum"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              outlined
              dense
              filled
              readonly
              label="Scanned By"
              v-model="record.creatorName"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              dense
              readonly
              label="Date"
              v-model="record.created_at"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              outlined
              dense
              readonly
              label="Result"
              v-model="record.state"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col
            cols="4"
            v-for="(dataField, i) in this.scanLogData"
            v-bind:key="i"
          >
            <p class="text-capitalize">
              {{ dataField.Name }}
            </p>
            <v-text-field
              outlined
              dense
              v-if="
                dataField.Type == 'Text' ||
                dataField.Type == 'Phone' ||
                dataField.Type == 'Dropdown'
              "
              readonly
              :value="dataField.Content"
            ></v-text-field>
            <v-row dense v-if="dataField.Type == 'Photo'">
              <v-col dense cols="12">
                <img
                  :src="dataField.Content"
                  height="380"
                  width="280"
                  contain
                  @click="openModal(dataField)"
                />
              </v-col>
              <v-col dense>
                <v-btn text color="primary" @click="downloadImage(dataField)">
                  Download <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-dialog v-model="modalOpen" max-height="100vh" width="600px">
          <v-card>
            <v-card-text>
              <v-img
                max-height="100vh"
                :src="selectedImage?.Content"
                aspect-ratio="1"
                contain
              ></v-img>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="primary" @click="downloadImage(selectedImage)">
                Download <v-icon>mdi-download</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="modalOpen = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snackbar ref="snackbar"></snackbar>
        <!-- end of territory dialog -->
      </v-card-text>
    </v-card>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    currencies: {
      type: Array,
    },
    itemsData: {
      type: Object,
    },
    pageMode: {
      type: String,
    },
  },
  data: () => ({
    seriesData: [],
    scanLogData: [],
    title: "",
    record: {},
    modalOpen: false,
    selectedImage: null,
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.scanLogData = val.scanLogDetails;
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    openModal(image) {
      console.log(image);
      this.selectedImage = image;
      this.modalOpen = true;
    },
    downloadImage(image) {
      console.log(image);
      const url = image.Content;
      const link = document.createElement("a");
      link.href = url;
      link.download = `${image.Name}-${image.DocEntry}.jpg`;
      link.click();
    },
  },
  created() {},
};
</script>